
import { defineComponent, onMounted } from "vue";
import SubscriptionDetails from "../components/SubscriptionDetails.vue";
import { setPageHeader } from "../../../core/helpers/toolbar";
export default defineComponent({
	components: {
		SubscriptionDetails,
	},
	setup() {
		onMounted(() => {
			setPageHeader({
				title: "message.SUBSCRIPTION_DETAIL",
				actionButton: {
					ability: "add_subscription",
					pageAction: {
						action: "openSubscriptionProductModel",
					},
					button: {
						icon: "bi bi-plus",
						openModal: true,
					},
				},
				breadCrumbs: [
					{ name: "message.HOME", link: "/dashboard" },
					{ name: "message.SUBSCRIPTIONS", link: "#" },
					{ name: "message.SUBSCRIPTIONS_ORDERS", link: "/subscription" },
					{ name: "message.SUBSCRIPTION_DETAIL" },
				],
			});
		});
	},
});
